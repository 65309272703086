import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="blog-details__main">
        <div className="blog-details__content px-5">
          <h3 className="text-center">TERMS AND CONDITIONS</h3>
          <p>
            We, CIGNA COMMUNICATION LLP, are the creators, owners and publishers
            of the ‘OPPAM’ application and website which include reference to as
            ‘We’ or ‘OPPAM’, and this includes directors, employees,
            subsidiaries and affiliates of ‘OPPAM’
          </p>
          <p>
            ‘OPPAM’ is predominantly engaged in providing service that supports
            the ‘User’ by way of confidential communications which ensures
            wellbeing of ‘Users’ by way of providing expert guidance, engaging
            empathetic listening and flexible communications as desired by the
            ‘User’.
          </p>
          <p>
            ‘OPPAM’ provide services through its Online Platform, which may be
            modified, deleted, updated (temporarily or permanently) from time to
            time as deemed necessary for providing smooth experience for the
            ‘Users’.
          </p>
          <h4>DEFINITIONS</h4>
          <ul>
            <li>
              ‘Account’ refers to the personal account created and maintained by
              the User for availing the Services of ‘OPPAM’.
            </li>
            <li>
              ‘Application’ means the mobile application of ‘OPPAM’ available
              for download and installation to the ‘Users’ on Google play Store
              or Apple app store.
            </li>
            <li>
              ‘Content’ means and include any information, text, graphics,
              photos or other materials uploaded, downloaded or appearing on or
              as a part of the Services.
            </li>
            <li>
              ‘Online Platform’ or ‘Platform’ means the website Oppam.co.in
            </li>
            <li>
              ‘Services’ include voice calls, sessions and consultations to the
              ‘Users’ and the various policies incorporated in terms of use that
              govern ‘User’ access to and use of the Online Platform/Application
              along with the services provided by ‘OPPAM’ including SMS, APIs,
              email notifications, ads, commerce services, Content and other
              covered services
            </li>
            <li>
              ‘Supporter’ refers to the person/persons engaged in communication
              with the ‘Users’ of ‘OPPAM’.
            </li>
            <li>
              ‘User’, ‘Users’, Users’ or ‘You’ shall refer to the persons’
              providing required personal information and by way of
              subscription/ recurring payment avails the service provided by
              ‘OPPAM’.
            </li>
          </ul>
          <h4>TERMS AND CONDITIONS</h4>
          <ul>
            <li>
              Your access to the Services of the Platform/ Application is
              subject to your agreement to be bound by all the Terms set forth
              herein and these Terms constitute an agreement between OPPAM and
              You in connection with your use of the Online Platform, as defined
              below. If You have any questions regarding Services, You can
              email  at Info@cigna.org.in .
            </li>
            <li>
              We may, if required change, modify, add or remove portions of
              these Terms, at any time and You may review these Terms
              periodically for updates / changes. Your continued use of the
              Platform following the changes confirms Your acceptance and
              agreement to such changes and You can engage in availing access
              and use the services.
            </li>
            <li>
              You understand that Supporters are individuals associating with
              ‘OPPAM’ to fulfil the Services of ‘OPPAM’ and that ‘OPPAM’ shall
              not be responsible for the conduct, behaviour or any declarations
              made by  the Supporters during his/her communication with You
              while availing the Services and any such incidents may be reported
              to ‘OPPAM’.
            </li>
            <li>
              You further understand, agrees and affirms that the services of
              ‘OPPAM’ shall not include providing any medical assistance or
              professional service to its Users and such needs if required are
              to be satisfied by approaching concerned certified Government
              agencies or departments.
            </li>
            <li>
              You understand that the Terms of this agreement are governed by
              and comply with the provisions of applicable Indian law which
              includes; The Indian Contract Act, 1872, The (Indian) Information
              Technology Act, 2000, Information Technology (Procedure and
              Safeguards for Interception, Monitoring and Decryption of
              Information) Rules, 2009, Information Technology (Procedure and
              Safeguards for Monitoring and Collecting Traffic Data or
              Information) Rules 2009, The rules, regulations, guidelines and
              clarifications framed there under, including the (Indian)
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Information) Rules, 2011 (the
              “SPI Rules”), and the (Indian) Information Technology
              (Intermediaries Guidelines) Rules, 2011 (the “IG Rules”) and the
              orders, rules, regulations, guidelines, notifications and
              clarifications issued by any legislative, regulatory or
              governmental authority at central, state or local level.
            </li>
            <li>
              You agree and understands that these Terms allows ‘OPPAM’ to
              access Registered Users’ contact information, including but not
              limited to email address and phone number for communication
              purposes so as to provide You with the Services and/or to obtain
              feedback, comments, suggestions, opinions, reviews in relation to
              Your use of the Platform/ Application/ the Services.
            </li>
            <li>
              You agree and affirm that You are 18 years of age or older and
              that you have the legal right, authority and capacity to use all
              or part of the Services available through the Platform, and agree
              to and abide by the Terms and You must not create a Customer
              account unless you are at least 18 years of age.
            </li>
            <li>
              You understand and acknowledge that the Platform and its Services
              are to be accessed by you on your own behalf or for your own
              personal use only and You shall not register on the Platform or
              use its Services on behalf of any third party or entity, nor shall
              you allow a third party or entity to use the Platform and its
              Services on your behalf.
            </li>
            <li>
              You agree that the access you have been granted prohibits you from
              using the Services for any illegal or unauthorized purpose. You
              will make every reasonable effort to prevent unauthorized third
              parties from accessing the Platform and the Services. You will
              make every reasonable effort to protect your device and account
              from a security breach, including but not limited to hacking,
              phishing etc. You represent and warrant that you will not breach
              the security of the Platform or its Application software hosted by
              or on behalf of ‘OPPAM’ used to deliver the Services or attempt to
              gain unauthorized access to or interfere with any other person's
              use of the Services.
            </li>
            <li>
              You agree and affirm that You shall not use this platform for
              engaging in any activity which shall be in contradiction to the
              interest of the security of the nation including any terrorist
              activities further that You shall not engage in interfering or
              forming association with any Supporters that you may interact with
              while availing the Services and also shall not engage in any
              communication/ conversation obscene in nature.
            </li>
          </ul>
          <h4>PROCEDUE FOR REGISTRATION</h4>
          <ul>
            <li>
              Your registration is required to fully avail the Services of the
              Platform/ Application. You are required to create a profile for
              Yourself by providing the following accurate information which
              inter alia includes Name, Phone No, Email ID. Users who register
              with ‘OPPAM’ can purchase the listed products/ packages and avail
              the Services. ‘OPPAM’ offers the Users an option of signing up
              through other third-party social networking sites, including but
              not limited to Whatzapp, etc. Upon such signup, our servers gain
              access to information about You from Your social networking
              account, including but not limited to Your profile, date of birth,
              name and gender, and all such information is stored in system of
              ‘OPPAM’.
            </li>
            <li>
              Information collected about you is subject to our Privacy Policy
              and at any time during Your use of this Platform/ Application, You
              may ensure that confidentiality of Your username and password is
              protected and any activity under the account shall be deemed to
              have been done by You.
            </li>
            <li>
              You hereby agree and consent to receiving calls, autodialled
              and/or pre-recorded message calls, e-mails and SMSs from ‘OPPAM’
              and/or any of its affiliates or partners at any time, subject to
              the Policy using this Platform by providing your contact
              information to us through the Platform and if you wish to stop
              receiving any such marketing or promotional calls / email messages
              / text messages, you may send an e-mail to the effect to
              INFO@CIGNA.ORG.IN and We shall give effect to such a request by
              You within 7 days time.
            </li>
          </ul>
          <h4>RIGHT TO LIMITED USE</h4>
          <ul>
            <li>
              The User agrees and undertakes not to reverse engineer, modify,
              copy, distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer, or sell any
              information or software obtained through the Platform. For the
              removal of any doubt, it is clarified that unlimited or complete
              reproduction, copying of the content for commercial or
              non-commercial purposes and unwarranted modification of data and
              information within the content of the Platform is not permitted.
            </li>
            <li>
              You acknowledge and agree that You have read and understood the
              Terms, that the provisions, disclosures and disclaimers set forth
              in the Terms are fair and reasonable, that Your agreement to
              follow and be bound by these terms and conditions is voluntary and
              that Your written approval is not a prerequisite to the validity
              or enforceability of the Terms.
            </li>
          </ul>
          <h4>TERMINATION</h4>
          <ul>
            <li>
              Termination by User: You have the option to terminate this
              Agreement at any time by deleting your Account and discontinuing
              all usage of the Platform. Please be aware that removing the
              Platform from your device will not automatically delete your
              Account. To delete your Account, you can drop an email to
              INFO@CIGNA.ORG.IN with the subject “Delete Account”. Deleting your
              Account will result in the automatic deletion of all User Content
              associated with it. Moreover, if you wish to remove specific User
              Content you've uploaded to the Platform, you can use the User
              Content deletion features within the Platform. However, please
              note that deleting User Content will not delete your Account or
              terminate this Agreement. This Agreement, including any posted
              revisions, will remain in full effect as long as you use the
              Platform, with certain provisions continuing to apply even after
              termination.
            </li>
            <li>
              Termination by Oppam: Oppam reserves the right to disable or
              terminate your user account, remove or disable any content you
              upload or share, at any time. This includes cases where you have
              failed to comply with any of the provisions of these Terms, or if
              activities on your account are deemed by Oppam, in its sole
              discretion, to potentially cause harm to or impair the Services,
              infringe upon or violate third-party rights, or contravene any
              applicable laws or regulations. Following such termination or
              suspension, you are prohibited from accessing or using the
              Platform. You also agree not to attempt re-registration under a
              different member name or by any other means.In such cases refund
              of amount or coins will also be under discussion.
            </li>
            <li>
              Termination of this Agreement, your account, or your access to or
              use of the Platform may entail the removal of access and the
              prevention of further use of the Platform. Termination also
              includes the disassociation of your username, password, and all
              associated information, files, and user content within your
              account. On termination, all your profile content and other
              information may be deleted. Nonetheless, certain details are
              retained by us for archival and legal purposes. Regardless of
              termination, content liability remains with the User at all times.
              Upon termination, your right to use the Platform, including mobile
              software, will cease automatically. Oppam assumes no liability to
              you for any suspension or termination, including the deletion of
              your user content. Oppam will retain and utilize content/data for
              as long as required and/or permissible under local laws. All
              provisions of this Agreement that, by their nature, should
              survive, will indeed survive termination, including but not
              limited to warranty disclaimers, governing law, and limitations of
              liability.
            </li>
          </ul>
          <h4>CANCELLATION AND REFUND POLICY</h4>
          <ul>
            <li>
              Cancellation Requests: Users can request a cancellation of the
              service by contacting our customer support team. Cancellation
              requests must be made within a specified timeframe.
            </li>
            <li>
              Cancellation Timeframe: Users must cancel the service at least 3
              hours before the scheduled start time to avoid any cancellation
              fees. Cancellations made beyond the specified timeframe may be
              subject to a cancellation fee.
            </li>
            <li>
              Cancellation Fees: Cancellation fees may vary depending on the
              reason for cancellation. The approximate cancellation fee amount
              will be communicated to the User at the time of booking or upon
              cancellation request.
            </li>
            <li>
              Refunds: If a cancellation request is eligible for a refund, the
              refund process will be initiated promptly. Refunds, if applicable,
              will be issued using the original payment method.
            </li>
            <li>
              Users may be eligible for a refund of their service fees under the
              following circumstances:
              <ul>
                <li>
                  Technical Failure:** If there is any known technical failure
                  from our end or any technical failure from your end which did
                  not enable you to stay connected to us beyond a few minutes.
                  Such technical fault has to be communicated to our
                  employee/listener whom you are interacting with or an email be
                  sent with the necessary information immediately.
                </li>
                <li>
                  Non-Delivery: If the service is not delivered within the
                  promised timeframe or is not provided at all.
                </li>
              </ul>
            </li>
            <li>
              To initiate the refund process, Users must contact the customer
              support of OPPAM and convey relevant details, such as the reason
              for the refund, the service details, and any supporting
              documentation.
            </li>
            <li>
              OPPAM will review the request and assess its eligibility once the
              refund request is received and the evaluation period may vary
              depending on the complexity of the service and the internal
              procedures of OPPAM.
            </li>
            <li>
              OPPAM will determine whether a refund is applicable after
              evaluating the refund request, and if the refund is approved,
              OPPAM will notify the user and initiate the refund process.
            </li>
            <li>
              The refund will typically be issued through the same payment
              method used for the original transaction unless otherwise
              specified by the user by way of written request or if technical
              limitations exist. The time it takes for the refunded amount to be
              credited may vary depending on the payment processor and financial
              institution. In case of any change in payment method, we will not
              be responsible for any non-receipt of the refund amount.
            </li>
            <li>The refund timeline will be with in 7 working days</li>
          </ul>
          <h4>REVIEWS, FEEDBACK AND DATA RETENTION</h4>
          <ul>
            <li>
              You may be invited by the Company to submit feedback, ratings,
              suggestions, comments, opinions or reviews on the Platform. It is
              up to your discretion for to submit the same.
            </li>
            <li>
              You are solely responsible for the information and content that
              you choose to submit to the Company on the Platform, including but
              not limited to the feedback, ratings, suggestions, comments,
              opinions or reviews relating to Products purchased by you. You
              agree not to post or publish any content on the Online Platform
              that amounts to:
              <ul>
                <li>
                  An infringement of any Company or third-party intellectual
                  property or privacy rights.
                </li>
                <li>
                  A violation of applicable laws or regulations, including but
                  not limited to the relevant rules under Information technology
                  Act, 2000.
                </li>
                <li>Defamation</li>
              </ul>
            </li>
            <li>
              OPPAM may choose, in its sole discretion, to use, analyse, and/or
              publish your reviews, ratings, opinion, comments and feedback in
              accordance with these Terms. You agree to be contacted by OPPAM
              via telephonic or electronic communication for the purposes of
              obtaining feedback in relation to the Products purchased, dispute
              resolution, complaint resolution, any further information. The
              information or content provided by You may be used by OPPAM for
              business purposes, including but not limited to market research,
              consumer behaviours and profiling, data analytics, tracking market
              trends on an anonymized basis.
            </li>
            <li>
              OPPAM is deemed to be a mere “intermediary” as defined under the
              applicable laws with respect to publishing of any information and
              content on the Platform and disclaims all responsibility and
              liability with respect to publishing of Your information and
              content. OPPAM is not liable to pay any consideration to you for
              republishing or repeatedly publishing any information or content
              provided by You on Platform.
            </li>
            <li>
              OPPAM may retain such information and content provide by you on
              the Platform for as long as necessary, depending on the type of
              information; purpose, means and modes of usage of such
              information; and according to the Sensitive Personal Information
              Rules.
            </li>
            <li>
              We have no obligation to monitor the materials posted on the
              Platform. We shall have the right to remove or edit any content
              that in its sole discretion violates, or is alleged to violate,
              any applicable law or either the spirit or letter of these Terms
              of Service. Notwithstanding this right, YOU REMAIN SOLELY
              RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE
              PLATFORM AND IN YOUR PRIVATE MESSAGES. In no event shall We assume
              or have any responsibility or liability for any Content posted or
              for any claims, damages or losses resulting from the use of
              Content and/or appearance of Content on the Platform. You hereby
              represent and warrant that You have all necessary rights in and to
              all Content Which You provide and all information it contains and
              that such Content shall not infringe any proprietary or other
              rights of third parties or contain any libelous, tortuous, or
              otherwise unlawful information.
            </li>
          </ul>
          <h4>LIMITATION OF LIABILITY</h4>
          <p>
            You may read this carefully as it limits the liability of OPPAM and
            its parents, subsidiaries, affiliates, related companies, officers,
            directors, employees, agents, representatives, partners, and
            licensors (collectively, the OPPAM) each of the subsections below
            only applies up to the maximum extent permitted under applicable
            law. Nothing in this section is intended to limit any rights you may
            have which may not be lawfully limited.
          </p>
          <ul>
            <li>
              Your access to and use of the Services or any Content are at your
              own risk. You understand and agree that the Services are provided
              to you on an “AS IS” and “AS AVAILABLE” basis without any
              representation or warranties, express or implied except otherwise
              specified in writing. Without limiting the foregoing, to the
              maximum extent permitted under applicable law, WE DISCLAIM ALL
              WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
              NON-INFRINGEMENT.
            </li>
            <li>
              Without prejudice to the foregoing, We make no warranty or
              representation and disclaim all responsibility and liability for:
              (i) the completeness, accuracy, availability, timeliness, security
              or reliability of the Services or any Content; (ii) any harm to
              your computer system, loss of data, or other harm that results
              from your access to or use of the Services or any Content; (iii)
              the deletion of, or the failure to store or to transmit, any
              Content and other communications maintained by the Services; and
              (iv) whether the Services will meet your requirements or be
              available on an uninterrupted, secure, or error-free basis. No
              advice or information, whether oral or written, obtained from us
              or through the Services, will create any warranty or
              representation not expressly made herein.
            </li>
            <li>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE SHALL NOT BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
              INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
              GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR
              ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES;
              (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES,
              INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL
              CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT
              OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORISED ACCESS, USE OR
              ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.
            </li>
            <li>
              IN NO EVENT SHALL THE AGGREGATE LIABILITY OF Oppam EXCEED THE
              AMOUNT YOU PAID TO US, FOR THE SERVICES GIVING RISE TO THE CLAIM.
            </li>
            <li>
              THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
              LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
              (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT WE HAVE
              BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A
              REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
              PURPOSE.
            </li>
          </ul>
          <h4>DISCLAIMER</h4>
          <ul>
            <li>
              Oppam hereby explicitly disclaims any responsibility for any form
              of payment, whether made directly or indirectly to any of its
              employees or listeners during the course of a session. This
              includes payments that may have been solicited or offered by you,
              the user. It is essential to understand that Oppam's billing
              system is solely based on charging users for the time spent during
              sessions, with charges being deducted from the wallet maintained
              in the user's account.
            </li>
            <li>
              We want to make it unequivocally clear that Oppam strictly
              prohibits and discourages any exchange of personal information
              between users and our employees or listeners. This encompasses
              sensitive data such as phone numbers, addresses, bank details, or
              any other personal information, whether it was voluntarily shared
              by you during the usage of our services or otherwise. We
              prioritize the privacy and security of our users and their
              information.
            </li>
            <li>
              OPPAM assures Users that Supporters do not have access to any
              personal information provided by You within the app. Therefore,
              any information that a Supporter may come across during or after a
              session will be assumed to have been willingly shared by You. We
              maintain stringent data protection protocols to safeguard the
              privacy and confidentiality of Users of OPPAM.
            </li>
            <li>
              Our services are designed exclusively for the purpose of providing
              a platform for interactive sessions. OPPAM vehemently discourages
              any attempt by Supporters to establish or extend personal
              relationships beyond the confines of the sessions sought for. Any
              intention or activity by Supporters or Users that falls outside
              the scope of these services is entirely beyond purview and
              responsibility of OPPAM.
            </li>
            <li>
              OPPAM has implemented several additional protective measures in
              its commitment to User safety and privacy. We neither endorse nor
              recommend any specific Supporter for personal interactions beyond
              our Services. Users are encouraged to utilize privacy settings
              within the app to control the dissemination of their personal
              information. Should Users encounter any form of inappropriate
              behaviour or solicitation from Supporters, We urge them to report
              such incidents immediately to our customer support team. Moreover,
              Users are reminded of their own responsibility to exercise caution
              and discretion when using our services and sharing personal
              information.
            </li>
            <li>
              OPPAM operates in strict compliance with all applicable laws and
              regulations governing data protection and privacy. Any violation
              of these laws by Users, or Supporters will be reported to the
              relevant authorities, and appropriate legal action will be
              initiated
            </li>
          </ul>
          <h4>INDEMNIFICATION</h4>
          <ul>
            <li>
              You agree to indemnify and hold harmless OPPAM and its officers,
              directors, employees and agents from any charges, complaints,
              damages, losses, liabilities, costs and expenses (including
              attorneys' fees and expert fees) due to, arising out of or
              relating in any way to any violation by you of the Terms,
              including but not limited to obligations stated therein relating
              to representation, warranty, intellectual property, access by
              minors, any applicable rule, regulation or law, your access or use
              of the Services and the Platform.
            </li>
          </ul>
          <h4>FORCE MAJEURE</h4>
          <p>
            OPPAM will not be responsible for a breach of the Terms, damages,
            losses, costs or expenses, caused by any failure or delay of
            performance of Services and disruption to the Platform if such
            failure or delay is caused by an act of war, floods, fires,
            inclement weather conditions, hostility, or sabotage, an act of God,
            pandemics, lockdowns, Labour stoppages, an electrical, internet,
            cellular network or telecommunication failure or power outage,
            government restrictions, political strikes or another event outside
            the reasonable control of OPPAM.
          </p>
          <h4>SEVERABILITY</h4>
          <ul>
            <li>
              In the event any provision of the Terms become void or
              unenforceable, it shall not affect the validity of these Terms as
              a whole, and the unenforceable provisions shall be severed and the
              remainder of the provisions of these Terms shall continue in full
              force and effect.
            </li>
          </ul>
          <h4>GOVERNING LAW AND JURISDICTION</h4>
          <ul>
            <li>
              For the purposes of the Terms:
              <ul>
                <li>
                  (a) the term "Dispute" will mean any dispute, difference or
                  claim arising out of or relating to: (i) the Terms, its
                  interpretation, or the breach, termination, applicability or
                  validity of the Terms; or (ii) any other dispute arising out
                  of or relating to the relationship between OPPAM and You
                </li>
                <li>
                  (b) the term “OPPAM" will mean and include its parents,
                  subsidiaries, affiliates, stockholders, directors, officers,
                  employees, agents, beneficiaries, assignees, successors in
                  interest, and any third party which provides products or
                  services purchased from or distributed by OPPAM
                </li>
                <li>
                  (c) the term User will mean you and those in privity with you,
                  your representatives, affiliates and family members.
                </li>
              </ul>
            </li>
            <li>
              These Terms shall be governed and interpreted by and construed in
              accordance with the substantive laws of India and subject to
              arbitration provisions below, each party hereby irrevocably and
              finally submits to the exclusive jurisdiction of the courts of
              Ernakulam, Kerala, India thereto in respect of any disputes, legal
              action or proceedings arising out of or in connection with the
              Terms, interpretation, duties, performance, breach, etc. of these
              Terms.
            </li>
            <li>
              Should any Dispute arise out of or in connection with these Terms,
              the Parties hereto shall first endeavor to settle such Dispute
              amicably. If the Dispute is not resolved through amicable
              settlement within fifteen (15) days after commencement of
              discussions or such longer period as the Parties mutually agree in
              writing, then either Party may refer the Dispute for resolution by
              arbitration according to the provisions of the Arbitration and
              Conciliation Act, 1996 (India) or any statutory amendment or
              re-enactment thereof, or any statute enacted to replace the same,
              for the time being in force. The arbitration shall be conducted by
              a sole arbitrator appointed by mutual consent of the parties. The
              seat and venue of arbitration shall be in Ernakulam, Kerala,
              India, and the language of the proceedings shall be English and
              Malayalam.
            </li>
            <li>
              The Award rendered in any arbitration commenced hereunder shall be
              final and conclusive, and judgment thereon may be entered in any
              court having jurisdiction for its enforcement. The Parties
              undertake to implement the arbitration award. In addition, the
              Parties agree that no Party shall have any right to commence or
              maintain a suit or legal proceeding concerning a Dispute hereunder
              (other than for preventive or interlocutory relief pending
              completion of the arbitration proceedings under these Terms) until
              the Dispute has been determined in accordance with the arbitration
              procedure provided for herein and then only for the enforcement of
              the award rendered in such arbitration. When any Dispute is under
              arbitration, except for the matters under dispute, the Parties
              shall continue to exercise their remaining respective rights and
              fulfil their remaining respective obligations under these Terms.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
